import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
//import Table from "components/Table/Table.js";
import TableStudents from "components/Table/TableStudents";
import CardBody from "components/Card/CardBody.js";
import PropTypes, { array } from "prop-types";
import axios from "axios";

class GridStudents extends Component {
  state = {
    students: [],
  };

  // componentDidMount() {
  //   //const response = await axios.get("/api/teachers/get-students");
  //   // const response = await axios.get(
  //   //   "http://mimapp.mimshakup.com/api/teachers/get-students"
  //   // );
  //   axios
  //     .get(`http://mimapp.mimshakup.com/api/teachers/get-students`)
  //     .then((res) => {
  //       const persons = res.data;
  //       console.log(persons);
  //       this.setState({ students: persons });
  //       // console.log(response);
  //       //this.setState({ students: response.data });
  //     });
  // }

  async componentDidMount() {
    // const response = await axios.get("/api/teachers/get-students");
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-students"
    );
    console.log(response);
    this.setState({ students: response.data });
  }

  render() {
    const { classes, xs, sm, md } = this.props;
    return (
      //<GridItem xs={12} sm={12} md={7}>
      <GridItem xs={xs} sm={sm} md={md}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>All Students</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            <TableStudents
              tableHeaderColor="warning"
              tableHead={["First Name", "Last Name", "E-mail", "City"]}
              tableData={this.state.students}
            />
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

export default GridStudents;

GridStudents.propTypes = {
  children: PropTypes.node,
};
